import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby"

import Layout from "../../../components/layout";
import ProjectHeader from "../../../components/layouts/ProjectHeader";
import { TechnologySection, TechEntry } from "../../../sections/Project-Page/TechnologySection";
import { BreakdownSection, BreakdownGrid } from "../../../sections/Project-Page/BreakdownSection";

const ProjectTurnip = () => {

    const data = useStaticQuery(
        graphql`
            query {
                mobileImage: file(relativePath: { eq: "project-turnip1.jpg" }) {
                    childImageSharp {
                    fluid(maxWidth: 490, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                    }
                }
                desktopImage: file(relativePath: { eq: "project-turnip1.jpg" }) {
                    childImageSharp {
                    fluid(quality: 100, maxWidth: 4160) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                    }
                }
                gallery1: file(relativePath: { eq: "project-turnip1.jpg" }) {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                      }
                    }
                }
                gallery2: file(relativePath: { eq: "project-turnip2.jpg" }) {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                      }
                    }
                }
                gallery3: file(relativePath: { eq: "project-turnip3.jpg" }) {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        `
    )
    
    const sourcesHeader = [
        data.mobileImage.childImageSharp.fluid,
        {
          ...data.desktopImage.childImageSharp.fluid,
          media: `(min-width: 491px)`,
        },
    ]

    const galleryImages = [
        data.gallery1.childImageSharp.fluid.src,
        data.gallery2.childImageSharp.fluid.src,
        data.gallery3.childImageSharp.fluid.src
    ];

    const headerContent = {
        github_link: "https://github.com/KyleRobertsIO/Project-Turnip",
        website_link: null,
        sources: sourcesHeader,
        headline: "Project Turnip",
        copy: "A match event tracker for Smash Bros. Melee, which displays combo strings and final results. Built on top of the open source Slippi SDK.",
        galleryImages: galleryImages
    }

    const TechUsed = [
        "NodeJS",
        "Electron"
    ]

    const TechEntries = TechUsed.map((techName) => (
        <TechEntry key={techName} TechItem={techName} />
    ))
    
    return (
        <Layout>
            <ProjectHeader content={headerContent} />
            <TechnologySection>
                {TechEntries}
            </TechnologySection>

            <BreakdownGrid>

                <BreakdownSection title="Details">
                    <p>
                        Project Turnip is a tool that breaks down the actions that took place inside
                        a Super Smash Bros. Melee match. 
                        It utilizes the <Link to="https://github.com/project-slippi/slippi-js" target="_blank">Project Slippi</Link> JavaScript SDK
                        that retrieves data from a saved binary event file. These files are produced 
                        from forked Dolphin Emulator clients or hacked Melee ISO's produced by Project Slippi.
                    </p>

                    <p>
                        Using NodeJS the tool can read the user's file system for the match event files. 
                        Combining NodeJS with Electron, the tool can run on a user's desktop using a 
                        highly customizable UI built with HTML, CSS and JavaScript to report data to a user. 
                    </p>
                </BreakdownSection>

                <BreakdownSection title="Lessons Learned">
                    <p>
                        Before having ever used Electron, my experience with desktop development
                        revolved around using either JavaFX or WinForms as the framework to 
                        construct UI's. The benefits of those frameworks were the drag and drop
                        workflows that made development easy as well as fast. The trade-off of the 
                        ease of use and speed was that you were forced into static interfaces with 
                        a lack of real customization.
                    </p>

                    <p>
                        Electron is a framework that can fulfill your needs for responsive UI and customization.
                        It enables you not just to build for desktop but as well use your code from web applications.
                        What could of speed up the development process of this project would on been to use React
                        and implement a UI framework such as Material UI for premade components. Through using 
                        the themes feature I could have accomplished the same design but in a fraction of the time.
                    </p>
                </BreakdownSection>

            </BreakdownGrid>

        </Layout>
    );

}

export default ProjectTurnip;